import React from 'react'
import Layout from '../components/layout'
import style from './contact.module.css'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

const encodeEntry = ([name, value]) => {
  return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
}

const Contact = ({ location, data }) => {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [error, setError] = React.useState(null)
  const [isMessageSent, setIsMessageSent] = React.useState(false)

  const settersByFieldName = {
    name: setName,
    email: setEmail,
    phone: setPhone,
    message: setMessage,
  }

  const clearForm = () => {
    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
  }

  const onChange = (event) => {
    const field = event.currentTarget
    settersByFieldName[field.name](field.value)
  }

  const onSubmit = async (event) => {
    // to be able to use in the event callback
    // store the action value in a variable
    const action = event.currentTarget.action

    event.preventDefault()

    setError(null)

    const form = parseForm(event.currentTarget)

    const formError = validateForm(form)

    if (formError) {
      setError(formError)
      return
    }

    gtag('event', 'conversion_event_contact', {
      event_callback: submitForm,
      event_timeout: 2000,
    })

    async function submitForm() {
      await fetch(action, {
        method: 'POST',
        body: Object.entries(form).map(encodeEntry).join('&'),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      clearForm()
      setIsMessageSent(true)
    }
  }

  return (
    <Layout
      location={location}
      packageTypes={data.allContentfulPackageType}
      photoshoots={data.contentfulEntriesGroup}
    >
      <>
        <Helmet>
          <title>Juliana de Souza Photography</title>

          <meta
            name="description"
            content="Get in touch with Juliana de Souza"
          />
        </Helmet>

        <p className={style.lead}>
          Do you have some questions or want to exchange some ideas? Let me
          know, send me a message below.
        </p>

        {isMessageSent && (
          <div className={style.successMessage}>
            The message was successfully sent.
          </div>
        )}

        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={onSubmit}
          className={style.form}
        >
          <input
            type="hidden"
            name="form-name"
            value="contact"
            className={style.input}
          />

          <p>
            <label>
              <input
                className={style.input}
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={onChange}
              />
            </label>

            {error?.name && <span className={style.error}>{error.name}</span>}
          </p>
          <p>
            <label>
              <input
                className={style.input}
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={onChange}
              />
            </label>

            {error?.email && <span className={style.error}>{error.email}</span>}
          </p>

          <p>
            <label>
              <input
                className={style.input}
                type="text"
                name="phone"
                placeholder="Phone number"
                value={phone}
                onChange={onChange}
              />
            </label>

            {error?.phone && <span className={style.error}>{error.phone}</span>}
          </p>

          <p>
            <label>
              <textarea
                className={style.textarea}
                name="message"
                placeholder="Message"
                value={message}
                onChange={onChange}
              />
            </label>

            {error?.message && (
              <span className={style.error}>{error.message}</span>
            )}
          </p>

          <p>
            <button type="submit" className={style.submit}>
              Send
            </button>
          </p>
        </form>
      </>
    </Layout>
  )
}

function parseForm(formElement) {
  const formData = new FormData(formElement)

  return Array.from(formData).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]: value,
    }),
    {}
  )
}

function validateForm(form) {
  const error = ['name', 'email', 'message'].reduce(
    (acc, fieldName) => ({
      ...acc,
      ...(!form[fieldName] ? { [fieldName]: 'The field is required.' } : {}),
    }),
    {}
  )

  return Object.keys(error).length > 0 ? error : null
}

export const pageQuery = graphql`
  query ContactPage {
    allContentfulPackageType {
      nodes {
        name
        slug
      }
    }

    contentfulEntriesGroup(type: { eq: "Photoshoot" }) {
      items {
        name
        slug
      }
    }
  }
`

export default Contact
